import React from "react"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

export default function SalesforceGuide() {
  return (
    <Layout
      keywords="Salesforce installation guide, Salesforce Service Cloud documentation"
      title="Set up IrisAgent AI on your Salesforce Service Cloud account"
      description="Documentation on how to integrate IrisAgent with your Salesforce Service Cloud account to supercharge your support operations with AI."
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/sf-docs/"
        />
      </Helmet>
      {/* <!-- ======= Breadcrumbs ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Salesforce Installation Guide</h2>
            <ol>
              <li>
                <Link to="/irisagent-docs/">Home</Link>
              </li>
              <li>Salesforce Guide</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}
      <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin:"10%" }}>

                    <h1>IrisAgent Installation Guide for Salesforce Service Cloud and CRM</h1>
                    <br/>
                    <h2>
                    Introduction
                    </h2>
                    <br/>

                    IrisAgent delivers proactive customer support with AI-powered alerting, automated resolution, and correlation of support tickets and product issues.

IrisAgent’s AI-powered support solution reduces support ticket resolution time by 45%, improves agent performance, and enhances communications between agents, customers, and internal technical teams to reduce escalations by 60%. It proactively discovers trending issues instead of waiting for customers to report them.
                    <br/>
                    <br/>
                    <h2>
                    Sign up on IrisAgent's Dashboard
                    </h2>
                    <br/>
                    <ol>
                    <li>
                    Go to the <a href="https://web.irisagent.com" target="_blank">IrisAgent dashboard</a> and sign in with your Salesforce credentials.
                    <img
                src="/img/docs-login.png"
                style={{
                  width: "99%",
                  padding: "5%",
                }}
                alt="SSO"
              />
                    </li>
                        <ol>
                            <li>
                                If connecting with a production Salesforce account, please select the <b>Continue with Salesforce</b> option.
                            </li>
                            <li>
                                If connecting with a Salesforce Sandbox account, please select the <b>Continue with Salesforce Sandbox</b> option.
                            </li>
                            <li>
                                In the subsequent login page, enter the username and password to login.<br/>
                                Note that selecting the <b>Use Custom Domain</b> option will not work, as the option to choose production/sandbox environment was provided in the previous step.
                            </li>
                        </ol>
                    <li>
                    Give the OAuth permissions and click on Allow.
                    </li>
                    </ol>

                    <h2>
                    Install the IrisAgent app in Salesforce account
                    </h2>
                    <br/>
                    <ol>
                    <li>
                    <b>[Production Salesforce Account]</b> Install the IrisAgent app from the <a href="https://appexchange.salesforce.com/listingDetail?listingId=a0N3A00000FZ4X6UAL&tab=e" target="_blank">Salesforce AppExchange</a>.
                        <ul>
                            <li>
                                <b>[Sandbox Salesforce account]</b> Open the IrisAgent’s Salesforce client <a href="https://test.salesforce.com/packaging/installPackage.apexp?p0=04t3h000004pMz1" target="_blank"> sandbox installation link </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                    Select the option <b>Install for All Users</b> (note that you can later restrict access to certain profiles). Click on <b>Install</b> after checking the acknowledgment. After installation, click on <b>Done</b>.
                    <br/><img
                src="/img/sf-docs-install.png"
                style={{
                  width: "99%",
                  padding: "5%",
                }}
                alt="SSO"
              />
                    </li>
                    <li>
                    Go to <b>App Manager</b> from <b>Setup</b>. Click on Manage (from the drop-down icon) for the <b>Iris_Support_Insights</b> app.
                    <br/><img
                src="/img/sf-docs-app-manager.png"
                style={{
                  width: "80%",
                  padding: "5%",
                }}
                alt="SSO"
              />
                    </li>
                    <li>
                    Under the <b>OAuth Policies</b> section, select the <b>Admin approved users are pre-authorized</b> option for <b>Permitted users</b>. Click on Save.
                    </li>
                    <li>
                    Click on <b>Manage Profiles</b> on the next page. Select the user profiles which should be able to access the IrisAgent app. Click on Save.
                    <br/><img
                src="/img/sf-docs-prof.png"
                style={{
                  width: "99%",
                  padding: "5%",
                }}
                alt="SSO"
              />
                    </li>
                    </ol>

                    <h2>
                    Displaying the IrisAgent app in your Salesforce account
                    </h2>
                    <br/>
                    You can display the app on your standard case page or add it to a Lightning Record page on the Lightning App Builder. We recommend you do both to show insights to support reps at most places.
                    <br/>
                    <br/>
                    <h3>
                    Displaying on Standard Case Page
                    </h3>
                    <br/>
                    <ol>
                    <li>
                    Go to <b>Object Manager</b> from Setup. You can directly search “Object Manager” in the Quick Find box.
                    </li>
                    <li>
                    Select <b>Case</b> and go to <b>Case Page Layouts</b>
                    </li>
                    <li>
                    Click on the <b>Page Layout Assignment</b> button on the top right.
                    <br/><img
                src="/img/sf-docs-pg.png"
                style={{
                  width: "80%",
                  padding: "5%",
                }}
                alt="SSO"
              />
                    </li>
                    <li>
                    Click on <b>Edit Assignment</b>. Click on the user profiles for whom you want to enable the IrisAgent app (and hence the page layout) and then select <b>Iris Case Layout</b> on the ‘Page Layout To Use’ option. Click on Save.
                    </li>
                    </ol>
                    <h3>
                    Displaying on Standard Case Page
                    </h3>
                    <br/>
                    <ol>
                    <li>
                    Go to <b>Setup</b>. Search for <b>Lightning App Builder</b> in the Quick Find box.
                    </li>
                    <li>
                    If you don’t see any Lightning Record Page there, you can choose to display the IrisAgent on Standard Case Page in the section or create a new Lightning Record Page for the Case object.
                    </li>
                    <li>
                    Open the Case Page your team uses for viewing cases and where you want to add the IrisAgent app in the Lightning App Builder.
                    </li>
                    <li>
                    Enter VisualForce in the search box on the left Components panel and drag <b>VisualForce</b> from the Standard component to the desired location on the page.
                    <br/><img
                src="/img/sf-docs-vf.png"
                style={{
                  width: "30%",
                  padding: "5%",
                }}
                alt="SSO"
              />
                    </li>
                    <li>
                    From the right panel, select <b>Iris_vf_case</b> under <b>VisualForce Page Name</b>. Set the <b>Height</b> to 600 pixels for a good user experience.
                    <br/><img
                src="/img/sf-docs-height.png"
                style={{
                  width: "30%",
                  padding: "5%",
                }}
                alt="SSO"
              />

                    </li>
                    <li>
                    Click on <b>Save</b>. If a pop box opens, click on <b>Activate</b>.
                    </li>
                    </ol>
                    <h2>
                    Update the IrisAgent app dimensions
                    </h2>
                    <br/>
                    By default, in the rendered view, the app will have a height of 900px and a width of 800px. You can update the dimensions by following the steps below:
                    <br/>
                    <ol>
                    <li>
                    Go to <b>Setup</b>. Enter <b>Visualforce Pages</b> in the Quick Find Box.
                    </li>
                    <li>
                    Open and edit the <b>Iris_vf_case</b> page.
                    </li>
                    <li>
                    Click on <b>Edit</b> and update the line below to add the desired height and width.
                    <br/>
                    <pre><code>
{/*                      <apex:canvasApp developerName="Iris_Support_Insights" height="600" width="350"/> */}
                    </code></pre>
                    </li>
                    </ol>
                    <h2>
                    Whitelisting users using PermissionSets
                    </h2>
                    <br/>
                    In one of the earliest steps, you might have added all the profiles for which IrisAgent App needs to be visible. If you’d like to whitelist the app without using Profiles or if you can’t use profiles, you can use PermissionSets to assign the users who are interested in using the irisAgent app.

                    Before proceeding, please ensure the Iris Support Insights App has no associated profiles.  If you’d like to confirm/remove it

                    <ol>
                    <li>
                    Go to Setup
                    </li>
                    <li>
                    In the <b>Quick Find Box</b>, type in <b>App Manager</b> and click on it
                    </li>
                    <li>
                    Click on <b>Manage</b> against the <b>Iris Support Insights</b> App,
                    </li>
                    <li>
                    Scroll down, and in the <b>Profiles</b> section, click on <b>Manage Profiles</b>.
                    </li>
                    <li>
                    Unselect any profiles that have been added and click on Save
                    </li>
                    </ol>

                    Creating a <b>PermissionSet</b>
                    <ol>
                    <li>
                    Go to Setup
                    </li>
                    <li>
                    In the Quick Find Box, type in <b>Permission Sets</b> and click on it
                    </li>
                    <li>
                    Click on the <b>New</b> button
                    </li>
                    <li>
                    Enter the <b>Label</b> and any other details if you need to. Click on Save. You’ll be redirected to the newly created permission set page.
                    </li>
                    <li>
                    In the Apps section, click on Visualforce Page Access.
                    <br/><img
                src="/img/sf-guide-app.png"
                style={{
                  width: "80%",
                  padding: "5%",
                }}
                alt="SSO"
              />
                    </li>
                    <li>
                    Click on <b>Edit</b>
                    </li>
                    <li>
                    In the Visualforce Page Access section, select  Iris_vf_case and add it. Click on Save
                    <br/><img
                src="/img/sf-docs-vfp.png"
                style={{
                  width: "80%",
                  padding: "5%",
                }}
                alt="SSO"
              />
                    </li>
                    <li>
                    Click on <b>Manage Assignments</b>
                    </li>
                    <li>
                    Click on <b>Add Assignments</b>
                    </li>
                    <li>
                    Select the users you want to give access to and click on <b>Assign</b>. You’ll be redirected to the success page. Click on Done
                    </li>
                    <li>
                    Assign the above created permission set for the Iris Support Insights App. To do that, Go to Setup and then <b>App Manager</b>.
                    </li>
                    <li>
                    Click on <b>Manage</b> against the <b>Iris Support Insights</b> App,
                    </li>
                    <li>
                    Scroll down and in the <b>Permission Sets</b> section, click on <b>Manage Permission Sets</b> and select & assign the newly created Permission Set from the above steps
                    </li>
                    </ol>

                    <h2>
                    Integrating IrisAgent with Salesforce CRM
                    </h2>
                    <br/>
                    This section is relevant if you are using a different ticketing provider (e.g., Zendesk, Intercom, etc.) and using Salesforce as your CRM.
                    <br/>
                    <ol>
                    <li>
                    After logging in to the <a href="https://web.irisagent.com" target="_blank">dashboard</a>, click on <b>Manage Integrations</b>
                    </li>
                    <li>
                    Click on the “Connect” button for Salesforce CRM
                    </li>
                    <li>
                    You will see the OAuth screen below. Click on “Allow”.
                    </li>
                    </ol>

                    <h2>
                    Permissions accessed by IrisAgent
                    </h2>
                    <br/>
                    <ol>
                    <li>
                    IrisAgent uses <a href="https://help.salesforce.com/s/articleView?id=sf.remoteaccess_oauth_tokens_scopes.htm&type=5" target="_blank">the “api” scope</a>.
                    </li>
                    <li>
                    The token obtained after the OAuth flow will have access to all the objects the logged-in user has access. Hence, a separate service user should be created for IrisAgent with sufficient permissions to access the objects required by IrisAgent.
                    </li>
                    <li>
                    Below are the objects accessed by IrisAgent
                    <ul>
                    <li>
                    Objects with Read permissions: CaseArticle, CaseSolution, Solution, Account, CaseTag, Contact, EmailMessage, LiveChatTranscript, User, Group, Knowledge__kav, Knowledge__DataCategorySelection
                    </li>
                    <li>
                    Objects with Read and Write permissions: Case, CaseComment
                    </li>
                    </ul>
                    </li>
                    </ol>
                    <br/><br/>

The above steps will complete the installation of IrisAgent. Please <a href="mailto:contact@irisagent.com" target="_blank">email us</a> once these steps are completed, and our team will start setting up the machine learning models for your account.
                    </div>
                    </div>
                    </section>

</Layout>
  )
}
